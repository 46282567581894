<template>
  <div class="d-flex flex-column">
    <h5 class="h5-text mb-2">Отчеты по лизинговым компаниям</h5>
    <div class="page-block flex-column mb-6">
      <div class="mb-10">
        <v-btn color="secondary" :to="`/admin/reports/lc-reports`" class="mr-3 white--text">Отчет по договору</v-btn>
        <v-btn color="secondary" outlined :to="`/admin/reports/lc-reports-ext`" class="white--text">Расширенный отчет</v-btn>
      </div>
      <v-row class="mt-3">
        <v-col
          cols="12"
          sm="3"
        >
          <v-autocomplete
            v-model="companyId"
            :items="subscribes"
            item-text="leasing_company.name"
            item-value="leasing_company.id"
            :return-object="false"
            outlined
            label="Выберите лизинговую компанию"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        ><v-menu
          ref="menu"
          v-model="dateSearchMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Дата начала отчета"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="dateSearchMenu = false"
          >
          </v-date-picker>
        </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-menu
            ref="menu2"
            v-model="dateSearchMenu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                label="Дата конца отчета"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateSearchMenu2 = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-btn
          color="secondary"
          x-large
          width="250"
          depressed
          class="mx-auto"
          @click="getReport()"
        >
          Посмотреть отчет
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="reportDialog" max-width="800" @click:outside="reportHtml = ''">
      <v-card>
        <v-card-title class="chat-black-101">
          <v-btn color="secondary" @click="downloadReport()">Скачать</v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-html="reportHtml"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { Http } from '../../../apis/Http'
  // import moment from 'moment'

  export default {
    data() {
      return {
        reportDialog: false,
        companyId: 0,
        subscribes: [],
        startDate: null,
        endDate: null,
        dateSearchMenu: false,
        dateSearchMenu2: false,
        reportHtml: '',
        filePath: ''
      }
    },
    async created() {
      const res = await Http.get('/admin/report-companies')
      if (res?.data) this.subscribes = res.data
    },

    methods: {

      closeDialog() {
        this.reportHtml = ''
        this.reportDialog = false
      },

      downloadReport() {
        Http.get('/admin/download-company-report', {
          params: {
            filePath: this.filePath
          },
          responseType: 'blob'
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            const fileName = this.filePath.split('/').pop()
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch(() => {
            this.showErrorSystemNotification('Ooops!')
          })
      },

      getReport() {
        Http.get('/admin/get-company-report', {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            companyId: this.companyId
          }
        })
          .then(response => {
            this.reportHtml = response.data.html
            this.filePath = response.data.docx
            this.reportDialog = true
          })
          .catch(() => {
            this.showErrorSystemNotification('Ooops!')
          })
      }
    }
  }
</script>
